import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import SEO from "../components/SEO"

const Error = () => {
  return (
    <Layout>
      <SEO title='404' description='This is not the page you are looking for' />
      <main className='error-page'>
        <div className=''>
          <h1>oops no page here</h1>
          <Link to='/' className='btn'>
            Home
          </Link>
        </div>
      </main>
    </Layout>
  )
}
export default Error
